/* eslint-disable */
import useUserStore from '@/stores/UserStore';
import deepEqual from 'fast-deep-equal';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { API_URL } from '@/env';
import { replicateRxCollection } from 'rxdb/plugins/replication';
import apiRequest from '@/helpers/apiRequest';

export const ParticipantSchema = {
  title: 'participant schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 100, // <- the primary key must have set maxLength
    },
    event_id: { type: 'string' },
    user_id: { type: ['string', 'null'] },
    superior_id: { type: ['string', 'null'] },
    // name: { type: 'string' }, // remove this later
    updated_at: { type: 'integer' },
    formfields: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          id: { type: 'string' },
          value: { type: ['string', 'null'] },
        },
      },
    },
    bookings: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          id: { type: 'string' },
          type: { type: 'string' }, // eventitem | variant
          eventitem_id: { type: ['string', 'null'] },
          variant_id: { type: ['string', 'null'] },

          isBooked: { type: 'boolean' },
          amount: { type: ['number', 'null'] },
          startdatetime: { type: ['string', 'null'] },
          enddatetime: { type: ['string', 'null'] },
        },
      },
    },
  },
  required: ['id'],
  indexes: [],
};

export const newParticipant = function (eventid: any) {
  return {
    id: uuidv4(),
    event_id: eventid,
    user_id: null,
    superior_id: null,
    formfields: [],
    bookings: [],
  };
};
export const ParticipantConflictHandler = function (i: any, _context: string) {
  //   console.log('participant conflict handler running: ', i, _context);

  if (deepEqual(i.newDocumentState, i.realMasterState)) {
    return Promise.resolve({
      isEqual: true,
    });
  }
  return Promise.resolve({
    isEqual: false,
    documentData: i.realMasterState,
  });
};

export async function ParticipantCreateReplication(db: any) {
  return replicateRxCollection({
    collection: db.participants,

    replicationIdentifier: 'rapivo-sync',

    live: true,

    retryTime: 5 * 1000,

    waitForLeadership: false,

    autoStart: true,

    deletedField: '_deleted',

    push: {
      async handler(docs) {
        const userStore = useUserStore();

        try {
          const response = await apiRequest({
            withCredentials: true,
            method: 'post',
            url: `${API_URL}/api/v1/participant`,
            data: {
              documents: docs,
            },
          });
          const documents: any = response.data.documents;
          console.log('participant pushHandler: sent', docs, 'received', documents);
          return documents;
        } catch (error) {
          console.error(error);
          return [];
        }

        /**
         * Contains an array with all conflicts that appeared during this push.
         * If there were no conflicts, return an empty array.
         */
      },

      batchSize: 1,

      modifier: (d) => d,
    },

    pull: {
      async handler(lastCheckpoint: any, batchSize) {
        const userStore = useUserStore();

        // make the pull request
        const lastTimestamp = lastCheckpoint ? (lastCheckpoint as any).lastTimestamp : 0;
        const lastTimestampId = lastCheckpoint ? (lastCheckpoint as any).lastTimestampId : null;

        // if the request fails, the handler fails, this correctly triggers a retry later
        const response = await apiRequest({
          withCredentials: true,
          method: 'patch',
          url: `${API_URL}/api/v1/participant`,
          data: {
            lastTimestamp: lastTimestamp,
            lastTimestampId: lastTimestampId,
            limit: batchSize,
          },
        });

        const documents: any = response.data.documents;

        // if documents received are [], then the checkpoint does NOT get updated
        let newCheckpoint = {};
        if (documents.length > 0) {
          const lastDocument = documents[documents.length - 1];
          newCheckpoint = { lastTimestamp: lastDocument.updated_at, lastTimestampId: lastDocument.id };
        } else {
          newCheckpoint = lastCheckpoint;
        }

        console.log(
          'Participant Pull: checkpoint:',
          lastCheckpoint,
          'received',
          documents,
          'newCheckpoint:',
          newCheckpoint
        );

        return {
          documents: documents,
          checkpoint: newCheckpoint,
        };
      },

      batchSize: 10,

      modifier: (d) => {
        return d;
      },
      /**
       * Stream of the backend document writes.
       * See below.
       * You only need a stream$ when you have set live=true
       */
      // stream$: pullStream$.asObservable(),
    },
  });
}

export class Participant {
  static getCostParticipant(eventitem: any, participantId: String, participants: any) {
    // console.log('eventitemGetCostParticipant', eventitemId, participantId);

    const participant = participants.find((p: any) => p.id === participantId);
    if (!participant) {
      return null;
    }
    const costBookings = Participant.getCostBookingsParticipant(eventitem!, participant, participants);

    // sum all costs
    let costsTotal = 0;
    for (const booking of costBookings) {
      costsTotal += booking.totalPrice;
    }
    // console.log('costsBooking: ', costBookings);

    costsTotal = roundTo2Decimals(costsTotal);

    return {
      total: costsTotal,
      costBookings: costBookings,
    };
  }

  static getCostBookingsParticipant(eventitem: any, participant: any, participants: any): any {
    let bookings = [];
    if (eventitem!.variants.length > 0) {
      // get unit price: only relevant if isSharedCosts eventitem
      const unitPrice = eventitem.isSharedCosts ? Participant.getVariantBasePrice(eventitem, participants) : 0;
      //   console.log(' variant unitPrice: ', unitPrice);

      // get all variants
      for (const variant of eventitem.variants) {
        // get all bookings for this variant of this participant
        const participantBookings = participant.bookings
          ? _.cloneDeep(
              participant.bookings.filter((booking: any) => booking.variant_id === variant.id && booking.isBooked)
            )
          : [];

        for (const booking of participantBookings) {
          //   booking.type = 'variant';
          booking.title = variant.name;
          if (eventitem.isSharedCosts) {
            // add pricing info to booking
            booking.unitPrice = roundTo2Decimals((variant.priceOrPercentage / 100) * unitPrice);
            booking.totalPrice = roundTo2Decimals(
              (variant.priceOrPercentage / 100) * unitPrice * (booking.amount ?? 1)
            );
          } else {
            // individual costs
            booking.unitPrice = roundTo2Decimals(variant.priceOrPercentage);
            booking.totalPrice = roundTo2Decimals(variant.priceOrPercentage * (booking.amount ?? 1));
          }
          bookings.push(booking);
        }
        // console.log('variants');
      }
    } else {
      // eventitem has NO variants
      // take from below
      const unitPrice = Participant.getUnitPrice(eventitem, participants);
      const participantBookings = participant.bookings
        ? _.cloneDeep(
            participant.bookings.filter((booking: any) => booking.eventitem_id === eventitem.id && booking.isBooked)
          )
        : [];
      //   console.log('no variants unitprice: ', unitPrice);

      for (const booking of participantBookings) {
        //   booking.type = 'eventitem';
        booking.title = '';
        if (eventitem.isSharedCosts) {
          // add pricing info to booking
          booking.unitPrice = roundTo2Decimals(unitPrice);
          booking.totalPrice = roundTo2Decimals(unitPrice * (booking.amount ?? 1));
        } else {
          // individual costs
          booking.unitPrice = roundTo2Decimals(eventitem.costsTotal);
          booking.totalPrice = roundTo2Decimals(eventitem.costsTotal * (booking.amount ?? 1));
        }
        bookings.push(booking);
      }
    }

    return bookings;
  }

  static getUnitPrice(eventitem: any, participants: any): any {
    // console.log('getUnitPrice', participants);
    if (eventitem.isSharedCosts) {
      // get all bookings (exclude those that explicitly did NOT book)
      //   const bookings = useRepo(ParticipantEventItem).where('eventitem_id', eventitem!.id).where('isBooked', true).get();
      const bookings1 = participants.flatMap((p: any) => p.bookings);

      const bookings = bookings1.filter((b: any) => {
        return b != undefined && b.eventitem_id === eventitem.id && b.isBooked;
      });

      let nr_of_units = 0;
      for (const booking of bookings) {
        nr_of_units += booking.amount ?? 1;
      }
      nr_of_units = Math.max(1, nr_of_units);
      return eventitem!.costsTotal / nr_of_units;
    } else {
      return eventitem!.costsTotal;
    }
  }

  // only usable for EventItems with isSharedCosts == true
  static getVariantBasePrice(eventitem: any, participants: any) {
    // get all variants
    let divisor = 0;
    for (const variant of eventitem.variants) {
      // get all bookings

      const bookings1 = participants.flatMap((p: any) => p.bookings);
      const bookings = bookings1.filter((b: any) => b != undefined && b.variant_id === variant.id);

      for (const booking of bookings) {
        // console.log('adding: ', booking.amount, variant.priceOrPercentage);
        divisor += (variant.priceOrPercentage / 100) * (booking.amount ?? 1);
      }
    }
    divisor = Math.max(1, divisor);

    return eventitem!.costsTotal / divisor;
  }
}

function roundTo2Decimals(number: number) {
  return parseFloat((number + Number.EPSILON).toFixed(2));
}
