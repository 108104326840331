
import { defineComponent, ref, watchEffect, computed } from 'vue';
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonBadge,
  useIonRouter,
} from '@ionic/vue';
import {
  ellipse,
  square,
  triangle,
  homeOutline,
  peopleOutline,
  listOutline,
  notificationsOutline,
  documentTextOutline,
} from 'ionicons/icons';
import { useRoute } from 'vue-router';
import { useSubscription } from '@vueuse/rxjs';
import useNotificationStore from '@/stores/NotificationStore';
import useUserStore from '@/stores/UserStore';
import { db } from '@/database';

export default defineComponent({
  name: 'TabsPage',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonBadge,
  },
  setup() {
    const ionRouter = useIonRouter();
    const userStore = useUserStore();
    const notificationStore = useNotificationStore();
    const route = useRoute();

    const event: any = ref({});
    const currentEventId: any = ref('');
    const iAmOrganiser = computed(() => userStore.id === event.value.created_by);

    watchEffect(async () => {
      if (route.path.startsWith('/event/') && route.params.eventid && currentEventId.value !== route.params.eventid) {
        currentEventId.value = route.params.eventid;

        useSubscription(
          db()
            .events.findOne({
              selector: {
                id: route.params.eventid,
              },
            })
            .$.subscribe((document: any) => {
              event.value = document;
            })
        );
      }
    });

    return {
      userStore,
      currentEventId,
      iAmOrganiser,
      event,
      notificationStore,
      route,
      ionRouter,
      ellipse,
      square,
      triangle,
      homeOutline,
      peopleOutline,
      listOutline,
      notificationsOutline,
      navigator,
      documentTextOutline,
    };
  },
  //   watch: {
  //     '$route.params.eventid': function () {
  //       console.log('****** . WATCHER!');
  //       if (this.currentEventId !== this.route.params.eventid) {
  //         this.currentEventId = this.route.params.eventid;

  //         // console.log('db used:', this.db);

  //         useSubscription(
  //           db()
  //             .events.findOne({
  //               selector: {
  //                 id: this.route.params.eventid,
  //               },
  //             })
  //             .$.subscribe((document: any) => {
  //               this.event!.id = document.id;
  //               //   this.event!.createdBy = document.createdBy;
  //               this.event!.user_id = document.user_id;
  //               this.event!.title = document.title; // works
  //               this.event!.date_start = document.date_start; // works
  //               this.event!.date_end = document.date_end; // works
  //               this.event!.description = document.description; // works

  //               this.iAmOrganiser = this.userStore.id === this.event.user_id;
  //               console.log('iAmOrganiser: ', this.iAmOrganiser);
  //             })
  //         );
  //       }
  //     },
  //   },
  computed: {
    // iAmOrganiser() {
    //   console.log('userid: ', this.userStore.id, this.event.user_id);
    //   return this.userStore.id === this.event.user_id;
    // },
    newNotifications() {
      return this.notificationStore.notifications.length;
    },
    showTabbar() {
      const route = this.route.path;
      return route.startsWith('/event/') && !route.startsWith('/event/add') && !route.includes('homeinvite');
    },
  },
  methods: {
    showDebug() {
      console.log('debug');
    },
  },
});
