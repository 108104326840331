/* eslint-disable */
import deepEqual from 'fast-deep-equal';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { API_URL } from '@/env';
import { replicateRxCollection } from 'rxdb/plugins/replication';
import useUserStore from '@/stores/UserStore';
import apiRequest from '@/helpers/apiRequest';

export const InvitationSchema = {
  title: 'invitation schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 100, // <- the primary key must have set maxLength
    },
    event_id: { type: 'string' },
    user_id: { type: 'string' },

    token: { type: ['string', 'null'] },

    // if pre-set superior
    superior_id: { type: ['string', 'null'] },
    // if we created the participant manually already
    // and want to invite that participant
    participant_id: { type: ['string', 'null'] },

    max_use_count: { type: ['integer', 'null'] },
    expiration_date: { type: ['string', 'null'] },

    updated_at: { type: 'integer' },
    _deleted: { type: 'boolean' },
  },
  required: ['id'],
  indexes: [],
};

export const newInvitation = function (eventId: string) {
  const userStore = useUserStore();
  return {
    id: uuidv4(),
    event_id: eventId,
    user_id: userStore.id,
  };
};

export const InvitationConflictHandler = function (i: any, _context: string) {
  //   console.log('invitation conflict handler running: ', i, _context);

  if (deepEqual(i.newDocumentState, i.realMasterState)) {
    return Promise.resolve({
      isEqual: true,
    });
  }

  return Promise.resolve({
    isEqual: false,
    documentData: i.realMasterState,
  });
};

export async function InvitationCreateReplication(db: any) {
  return replicateRxCollection({
    collection: db.invitations,
    replicationIdentifier: 'rapivo-sync',
    live: true,
    retryTime: 5 * 1000,
    waitForLeadership: false,
    autoStart: true,
    deletedField: '_deleted',

    push: {
      /**
       * returns an array with all conflicts that appeared during this push.
       * If there were no conflicts, return an empty array.
       */
      async handler(docs) {
        // if the request fails, the handler fails, this correctly triggers a retry later
        const response = await apiRequest({
          withCredentials: true,
          method: 'post',
          url: `${API_URL}/api/v1/invitation`,
          data: {
            documents: docs,
          },
        });
        const documents: any = response.data.documents;
        console.log('invitation pushHandler: sent', docs, 'received', documents);

        return documents;
      },

      batchSize: 1,
      modifier: (d: any) => {
        return d;
      },
    },

    pull: {
      async handler(lastCheckpoint: any, batchSize) {
        const lastTimestamp = lastCheckpoint ? (lastCheckpoint as any).lastTimestamp : 0;
        const lastTimestampId = lastCheckpoint ? (lastCheckpoint as any).lastTimestampId : null;

        // if the request fails, the handler fails, this correctly triggers a retry later
        const response = await apiRequest({
          withCredentials: true,
          method: 'patch',
          url: `${API_URL}/api/v1/invitation`,
          data: {
            lastTimestamp: lastTimestamp,
            lastTimestampId: lastTimestampId,
            limit: batchSize,
          },
        });
        const documents: any = response.data.documents;

        // if documents received are [], then the checkpoint does NOT get updated
        let newCheckpoint = {};
        if (documents.length > 0) {
          const lastDocument = documents[documents.length - 1];
          newCheckpoint = { lastTimestamp: lastDocument.updated_at, lastTimestampId: lastDocument.id };
        } else {
          newCheckpoint = lastCheckpoint;
        }

        console.log(
          'Invitation Pull, checkpoint:',
          lastCheckpoint,
          'received',
          documents,
          'newCheckpoint:',
          newCheckpoint
        );

        return {
          documents: documents,
          checkpoint: newCheckpoint,
        };
      },

      batchSize: 10,

      modifier: (d) => {
        // if (!d.title) {
        //   d.title = 'Kein Titel';
        // }
        return d;
      },

      /**
       * Stream of the backend document writes.
       * See below.
       * You only need a stream$ when you have set live=true
       */
      // stream$: pullStream$.asObservable(),
    },
  });
}
