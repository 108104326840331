/* eslint-disable */
import deepEqual from 'fast-deep-equal';

import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxConflictHandler,
  RxConflictHandlerInput,
  //   lastOfArray,
} from 'rxdb';

export const UserLocalSchemaLiteral = {
  title: 'userlocal',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 100, // <- the primary key must have set maxLength
    },
    value: {
      type: 'string',
    },
  },
  required: ['id', 'value'],
  indexes: [],
} as const;

const schemaTyped = toTypedRxJsonSchema(UserLocalSchemaLiteral);
export type RxUserLocalDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>;
export const UserLocalSchema: RxJsonSchema<RxUserLocalDocumentType> = UserLocalSchemaLiteral;

export const UserLocalConflictHandler: RxConflictHandler<RxUserLocalDocumentType> = function (
  i: RxConflictHandlerInput<RxUserLocalDocumentType>,
  _context: string
) {
  console.log('conflict handler running: ', i, _context);

  if (deepEqual(i.newDocumentState, i.realMasterState)) {
    return Promise.resolve({
      isEqual: true,
    });
  }
  return Promise.resolve({
    isEqual: false,
    documentData: i.realMasterState,
  });
};

// export async function UserLocalCreateReplication(db: any) {
//   return replicateRxCollection({
//     collection: db.UserLocals,

//     replicationIdentifier: 'rapivo-sync',

//     live: true,

//     retryTime: 5 * 1000,

//     waitForLeadership: false,

//     autoStart: false,

//     deletedField: '_deleted',

//     push: {
//       async handler(docs) {
//         console.log('*************** PUSH');
//         console.log(docs);

//         const rawResponse = await fetch('http://localhost:8003/api/sync/push', {
//           method: 'POST',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ docs }),
//         });

//         /**
//          * Contains an array with all conflicts that appeared during this push.
//          * If there were no conflicts, return an empty array.
//          */
//         const response = await rawResponse.json();
//         console.log('pushHandler received', response);
//         return response;
//       },

//       batchSize: 1,

//       modifier: (d) => d,
//     },

//     pull: {
//       async handler(lastCheckpoint: any, batchSize) {
//         console.log('//////////// PULL ');
//         console.log('using checkpoint: ', lastCheckpoint);
//         const lastTimestamp = lastCheckpoint ? (lastCheckpoint as any).lastTimestamp : 0;
//         const lastTimestampId = lastCheckpoint ? (lastCheckpoint as any).lastTimestampId : null;

//         const response = await fetch('http://localhost:8003/api/sync', {
//           method: 'POST',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             lastTimestamp: lastTimestamp,
//             lastTimestampId: lastTimestampId,
//             limit: batchSize,
//           }),
//         });

//         const { documents } = await response.json();
//         console.log(documents);

//         // if documents received are [], then the checkpoint does NOT get updated
//         let newCheckpoint = {};
//         if (documents.length > 0) {
//           console.log('creating new checkpoint');
//           const lastDocument = documents[documents.length - 1];
//           newCheckpoint = { lastTimestamp: lastDocument.updated_at, lastTimestampId: lastDocument.id };
//         } else {
//           console.log('using old checkpoint');
//           newCheckpoint = lastCheckpoint;
//         }

//         console.log('***** PULL DONE ', documents, newCheckpoint);

//         return {
//           documents: documents,
//           checkpoint: newCheckpoint,
//         };
//       },
//       batchSize: 1,

//       modifier: (d) => d,
//       /**
//        * Stream of the backend document writes.
//        * See below.
//        * You only need a stream$ when you have set live=true
//        */
//       // stream$: pullStream$.asObservable(),
//     },
//   });
// }
