import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, {
    onTouchstart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.preventSwipe($event)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_router_outlet, {
        id: "main-content",
        ref: "routerOutletRef"
      }, null, 512)
    ]),
    _: 1
  }))
}