/* eslint-disable */
import deepEqual from 'fast-deep-equal';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { API_URL } from '@/env';
import { replicateRxCollection } from 'rxdb/plugins/replication';
import useUserStore from '@/stores/UserStore';
import apiRequest from '@/helpers/apiRequest';

export const EventSchema = {
  title: 'event schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 100, // <- the primary key must have set maxLength
    },
    created_by: { type: 'string' },
    updated_at: { type: 'integer' },
    title: { type: 'string' },
    date_start: { type: ['string', 'null'] },
    date_end: { type: ['string', 'null'] },
    description: { type: ['string', 'null'] },
    _deleted: { type: 'boolean' },
    formfields: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          id: { type: 'string' },
          order: { type: 'integer' },
          title: { type: 'string' },
          type: { type: 'string' },
          required: { type: 'boolean' },
        },
      },
    },
    formfields_participant_displaynames: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    eventitems: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          id: { type: 'string' },
          name: { type: 'string' },
          isSharedCosts: { type: 'boolean' },
          isObligatory: { type: 'boolean' },
          isMultipleBookable: { type: 'boolean' },
          isAmountIncreasable: { type: 'boolean' },
          isEstimatedCosts: { type: 'boolean' },
          priceUnit: { type: ['string', 'null'] },
          costsTotal: { type: 'number' },

          startdatetime: { type: ['string', 'null'] },
          enddatetime: { type: ['string', 'null'] },

          minParticipants: { type: ['number', 'null'] },
          maxParticipants: { type: ['number', 'null'] },
          description: { type: ['string', 'null'] },

          variants: {
            type: 'array',
            uniqueItems: true,
            items: {
              type: 'object',
              properties: {
                id: { type: 'string' },
                name: { type: 'string' },
                priceOrPercentage: { type: 'number' },
              },
            },
          },
        },
      },
    },
  },
  required: ['id'],
  indexes: [],
};

export const newEvent = function () {
  const userStore = useUserStore();
  return {
    id: uuidv4(),
    created_by: userStore.id,
    title: '',
    date_start: null,
    date_end: null,
    description: null,

    formfields: [],
    formfields_participant_displaynames: [],
    eventitems: [],
  };
};

export const EventConflictHandler = function (i: any, _context: string) {
  //   console.log('event conflict handler running: ', i, _context);

  if (deepEqual(i.newDocumentState, i.realMasterState)) {
    return Promise.resolve({
      isEqual: true,
    });
  }

  return Promise.resolve({
    isEqual: false,
    documentData: i.realMasterState,
  });
};

export async function EventCreateReplication(db: any) {
  return replicateRxCollection({
    collection: db.events,
    replicationIdentifier: 'rapivo-sync',
    live: true,
    retryTime: 5 * 1000,
    waitForLeadership: false,
    autoStart: true,
    deletedField: '_deleted',

    push: {
      /**
       * returns an array with all conflicts that appeared during this push.
       * If there were no conflicts, return an empty array.
       */
      async handler(docs) {
        // if the request fails, the handler fails, this correctly triggers a retry later
        const response = await apiRequest({
          withCredentials: true,
          method: 'post',
          url: `${API_URL}/api/v1/event`,
          data: {
            documents: docs,
          },
        });
        const documents: any = response.data.documents;
        console.log('event pushHandler: sent', docs, 'received', documents);

        return documents;
      },

      batchSize: 1,
      modifier: (d: any) => {
        return d;
      },
    },

    pull: {
      async handler(lastCheckpoint: any, batchSize) {
        const lastTimestamp = lastCheckpoint ? (lastCheckpoint as any).lastTimestamp : 0;
        const lastTimestampId = lastCheckpoint ? (lastCheckpoint as any).lastTimestampId : null;

        // if the request fails, the handler fails, this correctly triggers a retry later
        const response = await apiRequest({
          withCredentials: true,
          method: 'patch',
          url: `${API_URL}/api/v1/event`,
          data: {
            lastTimestamp: lastTimestamp,
            lastTimestampId: lastTimestampId,
            limit: batchSize,
          },
        });
        const documents: any = response.data.documents;

        // if documents received are [], then the checkpoint does NOT get updated
        let newCheckpoint = {};
        if (documents.length > 0) {
          const lastDocument = documents[documents.length - 1];
          newCheckpoint = { lastTimestamp: lastDocument.updated_at, lastTimestampId: lastDocument.id };
        } else {
          newCheckpoint = lastCheckpoint;
        }

        console.log('Event Pull, checkpoint:', lastCheckpoint, 'received', documents, 'newCheckpoint:', newCheckpoint);

        return {
          documents: documents,
          checkpoint: newCheckpoint,
        };
      },

      batchSize: 10,

      modifier: (d) => {
        if (!d.title) {
          d.title = 'Kein Titel';
        }
        return d;
      },

      /**
       * Stream of the backend document writes.
       * See below.
       * You only need a stream$ when you have set live=true
       */
      // stream$: pullStream$.asObservable(),
    },
  });
}
