import { createRxDatabase, addRxPlugin } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/dexie';
// import { isPlatform } from '@ionic/vue';

import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { reactive } from 'vue';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { wrappedValidateAjvStorage } from 'rxdb/plugins/validate-ajv';
// import { humanSchema, humanConflictHandler, humanCreateReplication } from './models/human.schema';
// import { humanCreateReplication } from './models/human.schema';
import { UserLocalSchema } from './models/userLocal';
import { EventSchema, EventConflictHandler, EventCreateReplication } from './models/Event.schema';
// import { EventSchema } from './models/Event.schema';
import {
  ParticipantConflictHandler,
  ParticipantCreateReplication,
  ParticipantSchema,
} from './models/Participant.schema';
import { InvitationConflictHandler, InvitationCreateReplication, InvitationSchema } from './models/Invitation.schema';
// import { ParticipantConflictHandler, ParticipantSchema } from './models/Participant.schema';
import { humanSchema, humanConflictHandler, humanCreateReplication } from './models/human.schema';

addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBJsonDumpPlugin);

// function isDeviceReady(): Promise<void> {
//   return new Promise((resolve) => {
//     document.addEventListener('deviceready', () => {
//       resolve();
//     });
//   });
// }

const database: any = reactive({});
const replications: any = reactive({});

export function db(): any {
  return database.value;
}
export function replication(): any {
  return replications.value;
}

// export function initDatabase(): any {
//   database = reactive({});
// }

export async function createDatabase(userId: any) {
  //   console.log('start db');

  // this check is a problem if developing with Chrome devTools => deviceready event is not fired!
  //   if (isPlatform('mobile')) {
  //     await isDeviceReady();
  //   }

  // wrap the validation around the main RxStorage
  const storage = wrappedValidateAjvStorage({
    storage: getRxStorageDexie(),
  });

  database.value = await createRxDatabase({
    name: `u${userId}`, // prepend a 'u' because the name has to start with a letter, but the given uuid might start with a number
    storage,
  });

  await database.value.addCollections({
    events: {
      schema: EventSchema,
      conflictHandler: EventConflictHandler as any,
    },
    participants: {
      schema: ParticipantSchema,
      conflictHandler: ParticipantConflictHandler,
    },
    invitations: {
      schema: InvitationSchema,
      conflictHandler: InvitationConflictHandler,
    },
    humans: {
      schema: humanSchema,
      conflictHandler: humanConflictHandler,
      //   //   migrationStrategies: {
      //   //     // // 1 means, this transforms data from version 0 to version 1
      //   //     // 1(oldDoc) {
      //   //     //   oldDoc.height = 0;
      //   //     //   return oldDoc;
      //   //     // },
      //   },
    },
    userlocal: {
      schema: UserLocalSchema,
    },
  });

  replications.value = {
    humans: await humanCreateReplication(database.value),
    events: await EventCreateReplication(database.value),
    participants: await ParticipantCreateReplication(database.value),
    invitations: await InvitationCreateReplication(database.value),
  };
}
