import { defineStore } from 'pinia';
import { ref } from 'vue';

/* eslint-disable */

const useUserStore = defineStore('user', {
  state: () => ({
    id: ref<String | null>(null),
    // access_token: ref(''),
    isTemporaryUser: ref(false),
    formfields: ref([]) as any,
  }),
  getters: {},
  actions: {
    // test() {
    //   this.id++;
    // },
  },
  persist: {
    key: 'store.user',
    storage: localStorage,
  },
});

export default useUserStore;
