import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
// eslint-disable-next-line import/no-unresolved
import TabsPage from '@/views/event/TabsPage.vue';

// import participants from './participants.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/test',
    component: () => import('../views/Test.vue'),
  },
  {
    path: '/debug',
    component: () => import('../views/Debug.vue'),
    name: 'Debug',
  },
  {
    path: '/',
    component: TabsPage,
    children: [
      /**
       * HOME
       */
      {
        path: '',
        redirect: '/login',
      },
      {
        path: '',
        redirect: '/login',
      },
      {
        path: '/test',
        component: () => import('../views/Test.vue'),
      },
      {
        path: '/login/:token?',
        component: () => import('../views/login/Login.vue'),
        name: 'Login',
      },

      //     {
      //       path: '/login/:referrer?',
      //       component: () => import('../views/login/Login.vue'),
      //       name: 'Login',
      //     },
      //   //   {
      //     path: '/login/:token?',
      //     component: () => import('../views/login/LoginView.vue.vue'),
      //     name: 'Login',
      //   },
      {
        path: '/passwordforget',
        component: () => import('../views/login/PasswordForget.vue'),
        name: 'PasswordForget',
      },
      {
        path: '/passwordreset/:token/:email',
        component: () => import('../views/login/PasswordReset.vue'),
        name: 'PasswordReset',
      },
      {
        path: '/register',
        component: () => import('../views/login/Register.vue'),
        name: 'Register',
      },
      {
        path: '/register/confirm/:token',
        component: () => import('../views/login/RegisterConfirm.vue'),
        name: 'RegisterConfirm',
      },
      {
        path: '/logout',
        component: () => import('../views/login/Logout.vue'),
        name: 'Logout',
      },
      {
        path: '/invite/:invitetoken',
        component: () => import('../views/event/Event_Invite.vue'),
        name: 'EventInvite',
      },

      // EVENT INDEX
      {
        path: '/event',
        component: () => import('../views/event/Event_Index.vue'),
        name: 'EventIndex',
      },
      {
        path: '/event/add',
        component: () => import('../views/event/o/event/Event_Add.vue'),
        name: 'EventAdd',
      },

      /**
       * Participant Views
       */
      {
        path: '/event/p/:eventid/homeinvite',
        component: () => import('../views/event/p/home/P_View_HomeInvite.vue'),
        name: 'PViewHomeInvite',
      },
      {
        path: '/event/p/:eventid/home',
        component: () => import('../views/event/p/home/P_View_Home.vue'),
        name: 'PViewHome',
      },
      {
        path: '/event/p/:eventid/form',
        component: () => import('../views/event/p/form/P_View_Form.vue'),
        name: 'PViewForm',
      },
      {
        path: '/event/p/:eventid/cost',
        component: () => import('../views/event/p/cost/P_View_Cost.vue'),
        name: 'PViewCost',
      },
      {
        path: '/event/p/:eventid/participants',
        component: () => import('../views/event/p/participants/P_View_Participants.vue'),
        name: 'PViewParticipants',
      },

      /**
       * Organiser Views
       */
      {
        path: '/event/o/:eventid/home',
        component: () => import('../views/event/o/home/Home.vue'),
        name: 'HomeView',
      },

      {
        path: '/event/o/:eventid/event',
        name: 'Event_Tab',
        component: () => import('../views/event/o/event/Event_Tab.vue'),
      },
      {
        path: '/event/o/:eventid/event/eventdetails',
        name: 'Event_Show',
        component: () => import('../views/event/o/event/details/Event_Show.vue'),
      },
      {
        path: '/event/o/:eventid/event/eventdetails/edit',
        name: 'Event_Edit',
        component: () => import('../views/event/o/event/details/Event_Edit.vue'),
      },
      {
        path: '/event/o/:eventid/event/formfield',
        name: 'FormField_Edit',
        component: () => import('../views/event/o/event/formfield/FormField_Edit.vue'),
      },
      {
        path: '/event/o/:eventid/event/eventitem',
        name: 'EventItem_Index',
        component: () => import('../views/event/o/event/eventitem/EventItem_Index.vue'),
      },
      {
        path: '/event/o/:eventid/event/eventitem/add',
        name: 'EventItem_Add',
        component: () => import('../views/event/o/event/eventitem/EventItem_Add.vue'),
      },
      {
        path: '/event/o/:eventid/event/eventitem/:eventitemid/edit',
        name: 'EventItem_Edit',
        component: () => import('../views/event/o/event/eventitem/EventItem_Edit.vue'),
      },
      {
        path: '/event/o/:eventid/event/eventitem/:eventitemid',
        name: 'EventItem_Show',
        component: () => import('../views/event/o/event/eventitem/EventItem_Show.vue'),
      },
      {
        path: '/event/o/:eventid/event/invites',
        name: 'Invite_Index',
        component: () => import('../views/event/o/event/invite/Invite_Index.vue'),
      },

      /**
       * PARTICIPANTLIST
       */
      {
        path: '/event/o/:eventid/participant',
        component: () => import('../views/event/o/participant/Participant_Index.vue'),
        name: 'ParticipantList',
      },

      {
        path: '/event/o/:eventid/participant/add',
        name: 'ParticipantAdd',
        component: () => import('../views/event/o/participant/Participant_Add.vue'),
      },
      {
        path: '/event/o/:eventid/participant/:participantid/edit',
        component: () => import('../views/event/o/participant/Participant_Edit.vue'),
        name: 'ParticipantEdit',
      },
      {
        path: '/event/o/:eventid/participant/:participantid',
        component: () => import('../views/event/o/participant/Participant_Show.vue'),
        name: 'ParticipantShow',
      },

      /**
       * NOTIFICATIONS
       */
      //   {
      //     path: '/event/o/:eventid/notification',
      //     component: () => import('../views/event/o/notification/Notifications_Index.vue'),
      //     name: 'Notification',
      //   },
    ],
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;
