import { defineStore } from 'pinia';
import { ref } from 'vue';

const useNotificationStore = defineStore('notifications', {
  persist: {
    key: 'store.notifications',
    storage: localStorage,
  },
  state: () => ({
    notifications: ref([]) as any,
    // format:
    // {
    // id: '-1',
    // headline: 'Headline',
    // message: 'this is a very important notification',
    // priority: 1,
    // solved: false,
    // seen: false,
    // time: Date.now(),
    //   },
  }),
  getters: {
    openNotifications(): any[] {
      return this.notifications.filter((n: any) => !n.seen);
    },
  },
  actions: {
    markSeen(id: string) {
      const index = this.notifications.findIndex((n: any) => n.id === id);
      if (index >= 0) {
        this.notifications[index].seen = true;
      }
    },
  },
});
export default useNotificationStore;
