import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          (_ctx.showTabbar)
            ? (_openBlock(), _createBlock(_component_ion_tab_bar, {
                key: 0,
                slot: "bottom"
              }, {
                default: _withCtx(() => [
                  (_ctx.iAmOrganiser)
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 0,
                        tab: "HOME",
                        href: '/event/o/' + _ctx.event.id + '/home'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.homeOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("HOME")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["href"]))
                    : _createCommentVNode("", true),
                  (_ctx.iAmOrganiser)
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 1,
                        tab: "EVENT",
                        href: '/event/o/' + _ctx.event.id + '/event'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.listOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("EVENT")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["href"]))
                    : _createCommentVNode("", true),
                  (_ctx.iAmOrganiser)
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 2,
                        tab: "TEILNEHMER",
                        href: '/event/o/' + _ctx.event.id + '/participant'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.peopleOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("TEILNEHMER")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["href"]))
                    : _createCommentVNode("", true),
                  (!_ctx.iAmOrganiser)
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 3,
                        tab: "HOME",
                        href: '/event/p/' + _ctx.event.id + '/home'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.homeOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("HOME")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["href"]))
                    : _createCommentVNode("", true),
                  (!_ctx.iAmOrganiser)
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 4,
                        tab: "FORMULAR",
                        href: '/event/p/' + _ctx.event.id + '/form'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.documentTextOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("FORMULAR")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["href"]))
                    : _createCommentVNode("", true),
                  (!_ctx.iAmOrganiser)
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 5,
                        tab: "KOSTEN",
                        href: '/event/p/' + _ctx.event.id + '/cost'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.listOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("KOSTEN")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["href"]))
                    : _createCommentVNode("", true),
                  (!_ctx.iAmOrganiser)
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 6,
                        tab: "TEILNEHMER",
                        href: '/event/p/' + _ctx.event.id + '/participants'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.peopleOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Teilnehmer")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["href"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}