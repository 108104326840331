import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import { createPinia } from 'pinia';

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
// eslint-disable-next-line import/no-unresolved, import/extensions
import router from './router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/* Theme variables */
import './theme/variables.css';
import useUserStore from './stores/UserStore';
// import { createDatabase, useDatabase } from './database';
import { createDatabase } from './database';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App).use(IonicVue).use(pinia).use(router);

router.isReady().then(async () => {
  // check if user is logged in already
  // this is only executed if page is reloaded when user is logged in already
  // i.e. in user store there is a user id set
  //   const userStore = useUserStore();
  //   if (userStore.id) {
  //     const database = await createDatabase(userStore.id);
  //     app.use(database);
  //   }
  app.mount('#app');
});

// all routes below /event require login!
router.beforeEach(async (to) => {
  console.log('to: ', to);

  const userStore = useUserStore();
  if (to.fullPath.startsWith('/event') || to.fullPath.startsWith('/test')) {
    if (userStore.id) {
      // try creating DB
      // only necessary upon reload of the page
      // because then the user is logged in already and db was not created upon login
      // With every navigation this fails because database was created already
      try {
        await createDatabase(userStore.id);
      } catch (error) {
        // do nothing
      }
    } else {
      return { name: 'Login', query: { referrer: encodeURIComponent(to.fullPath) } };
    }
  }
  return undefined;
});
export default app;

// const database = createDatabase('78fb1982-fc5e-4cb1-965d-ef3ae66e9ee0');
// database
//   .then((db: any) => {
//     app.use(db).use(router);
//   })
//   .then(() => router.isReady())
//   .then(() => app.mount('#app'))
//   .then(() => {
//     // sample userId
//     const userStore = useUserStore();
//     userStore.id = 'FFF61EF6-A2B6-480E-98CF-AACF45E4E8D3';

//     // sample custom formfields
//     let index = userStore.formfields.findIndex((ff: any) => ff.id === '-E1FF5');
//     if (index < 0) {
//       userStore.formfields.push(
//         { id: '-E1FF5', order: 0, title: 'Fahrrad', field_type: 'text' },
//         { id: '-E1FF6', order: 4, title: 'Haustiere', field_type: 'text' }
//       );
//     }

//     // sample templates (formfields only for now)
//     const templateStore = useTemplateStore();
//     index = templateStore.templates.findIndex((t: any) => t.id === 'samples');
//     if (index < 0) {
//       console.log('not yet exists');
//       templateStore.templates.push({
//         id: 'samples',
//         eventitems: [],
//         formfields: [
//           { id: '-E1FF1', order: 0, title: 'Name', field_type: 'text' },
//           { id: '-E1FF2', order: 2, title: 'Alter', field_type: 'number' },
//           { id: '-E1FF3', order: 1, title: 'Geburtsdatum', field_type: 'date' },
//         ],
//       });
//     }

//     // sample notifications
//     const notificationStore = useNotificationStore();

//     const indexNotification = notificationStore.notifications.findIndex((n: any) => n.id === '-1');
//     if (indexNotification < 0) {
//       notificationStore.notifications.push(
//         {
//           id: '-1',
//           headline: 'Headline',
//           message: 'this is a very important notification',
//           priority: 1,
//           solved: false,
//           seen: false,
//           time: Date.now(),
//         },
//         {
//           id: '-2',
//           headline: 'Headline',
//           message: 'this is a LESS important notification',
//           priority: 2,
//           solved: false,
//           seen: false,
//           time: Date.now(),
//         },
//         {
//           id: '-3',
//           headline: 'Headline',
//           message: 'this is a very important SUBORDINATE notification',
//           priority: 1,
//           solved: false,
//           seen: false,
//           time: Date.now(),
//         },
//         {
//           id: '-4',
//           headline: 'Headline',
//           message: 'this is a LESS important SUBORDINATE notification',
//           priority: 2,
//           solved: false,
//           seen: false,
//           time: Date.now(),
//         }
//       );
//     }
//   });

// // useRepo(FormfieldTemplate).save([

// // ]);

// // useRepo(FormfieldCustom).save([
// //   { id: '-E1FF5', order: 0, title: 'Fahrrad', field_type: 'text' },
// //   { id: '-E1FF6', order: 4, title: 'Haustiere', field_type: 'text' },
// // ]);
// // router.isReady().then(() => {
// //   app.mount('#app');
// // });
// // Formfield.insert({
// //   data: [
// //     { id: 'FF1', title: 'name' },
// //     { id: 'FF2', title: 'age' },
// //   ],
// // });

// // Participant.insert({
// //   data: [
// //     { id: 'P1', name: 'Peter', formfields: [{ id: 'FF1', pivot: { value: 'Peter S.' } }] },
// //     { id: 'P2', name: 'Steven', formfields: [{ id: 'FF1', pivot: { value: 'Steven R.' } }] },
// //   ],
// // });

// // Participant.insert({
// //   data: [
// //     //   { id: 'P1', name: 'Peter', formfields: [{ id: 'FF1', pivot: { value: 'Peter S.' } }] },
// //     { id: 'P2', name: 'Steven', formfields: [{ id: 'FF1', pivot: { value: 'Steven R.' } }] },
// //   ],
// // });

// // useRepo(User).save([{ id: '-U_Ben', name: 'Ben' }]); // , participants: [{ id: '-U_Ben_P1', name: 'Ben' }] }]);
// // useRepo(User).save([{ id: '-U_Carsten', isMe: true, name: 'Carsten' }]);
// // useRepo(User).save([{ id: '-U_Katha', name: 'Katha' }]);

// // useRepo(FormfieldTemplate).save([
// //   { id: '-E1FF1', order: 0, title: 'Name', field_type: 'text' },
// //   { id: '-E1FF2', order: 2, title: 'Alter', field_type: 'number' },
// //   { id: '-E1FF3', order: 1, title: 'Geburtsdatum', field_type: 'date' },
// // ]);

// // useRepo(FormfieldCustom).save([
// //   { id: '-E1FF5', order: 0, title: 'Fahrrad', field_type: 'text' },
// //   { id: '-E1FF6', order: 4, title: 'Haustiere', field_type: 'text' },
// // ]);

// // useRepo(Event).save([
// //   {
// //     id: '-E1',
// //     title: 'Skireise 2022',
// //     date_start: '2022-12-01T00:00:50+01:00',
// //     date_end: '2022-12-14T00:00:50+01:00',
// //     description: 'Viel Spaß und Ski mit alten und neuen Freunden',
// //     formfields: [
// //       { id: '-E1FF1xxx', order: 0, title: 'Name', field_type: 'text' },
// //       { id: '-E1FF2xxx', order: 2, title: 'Alter', field_type: 'number' },
// //     ],
// //     participants: [
// //       {
// //         id: '-U_Ben_P1',
// //         name: 'Ben name',
// //         user_id: '-U_Ben',
// //         formfields: [
// //           { id: '-E1FF1xxx', pivot: { value: 'Ben S.' } },
// //           //   { id: '-E1FF2xxx', pivot: { value: 36 } },
// //         ],
// //       },
// //       {
// //         id: '-U_Katha_P1',
// //         name: 'Katha name',
// //         user_id: '-U_Katha',
// //         superior_id: '-U_Carsten_P1',

// //         formfields: [
// //           { id: '-E1FF1xxx', pivot: { value: 'Katha L.' } },
// //           { id: '-E1FF2xxx', pivot: { value: 32 } },
// //         ],
// //       },
// //       {
// //         id: '-U_Carsten_P1',
// //         name: 'Carsten name',
// //         user_id: '-U_Carsten',
// //         formfields: [
// //           { id: '-E1FF1xxx', pivot: { value: 'Carsten R.' } },
// //           { id: '-E1FF2xxx', pivot: { value: 36 } },
// //         ],
// //       },
// //     ],
// //     eventitems: [
// //       {
// //         id: '-E1EI1',
// //         name: 'Hausmiete',
// //         category: null, // 'Unterkunft',
// //         startdatetime: null, // '2022-04-27T00:00:00+02:00',
// //         enddatetime: null, // '2022-04-28T00:00:00+02:00',
// //         minParticipants: null, // 4,
// //         maxParticipants: null, // 20,
// //         description: null, // 'Wir mieten gemeinsam ein Haus mit 2-3 Personen Zimmern',
// //         isMultipleBookable: true,
// //         isPartiallyBookable: true,
// //         isObligatory: true,
// //         isSharedCosts: true,
// //         isEstimatedCosts: false,
// //         costsTotal: 2400,
// //         costsTotalCurrency: 'EUR',
// //         participants: [
// //           { id: '-U_Ben_P1', name: 'Ben', pivot: { value: 'eventitem participant' } },
// //           //   { id: '-U_Carsten_P1', name: 'Carsten', pivot: { isBooked: null, value: 'eventitem participant 1111' } },
// //         ],
// //         variants: [
// //           {
// //             id: '-1',
// //             name: 'variant 1',
// //             priceOrPercentage: 100,
// //             participants: [{ id: '-U_Ben_P1', pivot: { value: 'variant participant ' } }],
// //           },
// //           {
// //             id: '-2',
// //             name: 'variant 2',
// //             priceOrPercentage: 50,
// //             participants: [
// //               {
// //                 id: '-U_Carsten_P1',
// //                 pivot: {
// //                   isBooked: true,
// //                   amount: 3,
// //                   value: 'variant participant ',
// //                   startdatetime: '2022-04-27T00:00:00+02:00',
// //                   enddatetime: '2022-04-28T00:00:00+02:00',
// //                 },
// //               },
// //             ],
// //           },
// //         ],
// //       },
// //       {
// //         id: '-E1EI2',
// //         name: 'item 2',
// //         category: null, // 'Unterkunft',
// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',
// //         minParticipants: null, // 4,
// //         maxParticipants: null, // 20,
// //         description: null, // 'Wir mieten gemeinsam ein Haus mit 2-3 Personen Zimmern',
// //         isMultipleBookable: true,
// //         isPartiallyBookable: false,
// //         isObligatory: false,
// //         isSharedCosts: true,
// //         isEstimatedCosts: false,
// //         costsTotal: 2400,
// //         costsTotalCurrency: 'EUR',
// //         participants: [
// //           { id: '-U_Ben_P1', name: 'Ben', pivot: { isBooked: true, value: 'eventitem participant' } },
// //           //   {
// //           //     id: '-U_Carsten_P1',
// //           //     name: 'Carsten',
// //           //     pivot: {
// //           //       isBooked: false,
// //           //       amount: 3,
// //           //       startdatetime: '2022-01-02',
// //           //       enddatetime: '2022-01-03',
// //           //       value: 'eventitem participant',
// //           //     },
// //           //   },
// //         ],
// //         variants: [
// //           {
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [{ id: -1, pivot: { value: 'variant participant ' } }],
// //           },
// //           {
// //             name: 'variant 2',
// //             priceOrPercentage: 70.0,
// //             participants: [{ id: '-U_Carsten_P1', pivot: { value: 'variant participant ' } }],
// //           },
// //           //   { id: -4, name: 'variant 2', participants: [{ id: -2, pivot: { value: 'variant participant ' } }] },
// //         ],
// //       },
// //       {
// //         id: '-E1EI3',
// //         name: 'item 3',
// //         category: null, // 'Unterkunft',
// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',
// //         minParticipants: null, // 4,
// //         maxParticipants: null, // 20,
// //         description: null, // 'Wir mieten gemeinsam ein Haus mit 2-3 Personen Zimmern',
// //         isMultipleBookable: true,
// //         isPartiallyBookable: true,
// //         isObligatory: true,
// //         isSharedCosts: true,
// //         isEstimatedCosts: false,
// //         costsTotal: 2400,
// //         costsTotalCurrency: 'EUR',
// //         participants: [
// //           { id: '-U_Ben_P1', name: 'Ben', pivot: { value: 'eventitem participant' } },
// //           {
// //             id: '-U_Carsten_P1',
// //             name: 'Carsten',
// //             pivot: { amount: 2, value: 'eventitem participant' },
// //           },
// //         ],
// //         variants: [
// //           //   { id: -3, name: 'variant 1', participants: [{ id: -1, pivot: { value: 'variant participant ' } }] },
// //           //   { id: -4, name: 'variant 2', participants: [{ id: -2, pivot: { value: 'variant participant ' } }] },
// //         ],
// //       },

// //       {
// //         id: '-E1EI4',
// //         name: 'item 4',
// //         category: null, // 'Unterkunft',
// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',
// //         minParticipants: null, // 4,
// //         maxParticipants: null, // 20,
// //         description: null, // 'Wir mieten gemeinsam ein Haus mit 2-3 Personen Zimmern',
// //         isMultipleBookable: true,
// //         isPartiallyBookable: true,
// //         isObligatory: false,
// //         isSharedCosts: true,
// //         isEstimatedCosts: false,
// //         costsTotal: 2400,
// //         costsTotalCurrency: 'EUR',
// //         participants: [
// //           { id: '-U_Ben_P1', name: 'Ben', pivot: { isBooked: true, value: 'eventitem participant' } },
// //           {
// //             id: '-U_Carsten_P1',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 2, value: 'eventitem participant' },
// //           },
// //           //   {
// //           //     id: '-U_Carsten_P1',
// //           //     name: 'Carsten',
// //           //     pivot: { isBooked: true, amount: 2, value: 'eventitem participant' },
// //           //   },
// //         ],
// //         // variants: [
// //         //   { id: -3, name: 'variant 1', participants: [{ id: -1, pivot: { value: 'variant participant ' } }] },
// //         //   { id: -4, name: 'variant 2', participants: [{ id: -2, pivot: { value: 'variant participant ' } }] },
// //         // ],
// //       },

// //       {
// //         id: '-E1EI5',
// //         name: 'item 5, no variants, no shared',
// //         category: null, // 'Unterkunft',
// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',
// //         minParticipants: null, // 4,
// //         maxParticipants: null, // 20,
// //         description: null, // 'Wir mieten gemeinsam ein Haus mit 2-3 Personen Zimmern',
// //         isMultipleBookable: true,
// //         isPartiallyBookable: true,
// //         isAmountIncreasable: true,
// //         isObligatory: false,
// //         isSharedCosts: false,
// //         isEstimatedCosts: false,
// //         costsTotal: 100,
// //         costsTotalCurrency: 'EUR',
// //         participants: [
// //           { id: '-U_Ben_P1', name: 'Ben', pivot: { isBooked: true, value: 'eventitem participant' } },
// //           {
// //             id: '-U_Carsten_P1',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 2, value: 'eventitem participant' },
// //           },
// //           //   {
// //           //     id: '-U_Carsten_P1',
// //           //     name: 'Carsten',
// //           //     pivot: { isBooked: true, amount: 2, value: 'eventitem participant' },
// //           //   },
// //         ],
// //         // variants: [
// //         //   { id: -3, name: 'variant 1', participants: [{ id: -1, pivot: { value: 'variant participant ' } }] },
// //         //   { id: -4, name: 'variant 2', participants: [{ id: -2, pivot: { value: 'variant participant ' } }] },
// //         // ],
// //       },

// //       {
// //         id: '-E1EI6',
// //         name: 'item 5, variants, no shared',
// //         category: null, // 'Unterkunft',
// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',
// //         minParticipants: null, // 4,
// //         maxParticipants: null, // 20,
// //         description: null, // 'Wir mieten gemeinsam ein Haus mit 2-3 Personen Zimmern',
// //         isMultipleBookable: true,
// //         isPartiallyBookable: true,
// //         isObligatory: false,
// //         isSharedCosts: false,
// //         isEstimatedCosts: false,
// //         costsTotal: 100,
// //         costsTotalCurrency: 'EUR',
// //         participants: [
// //           { id: '-U_Ben_P1', name: 'Ben', pivot: { isBooked: true, value: 'eventitem participant' } },
// //           {
// //             id: '-U_Carsten_P1',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 2, value: 'eventitem participant' },
// //           },
// //           //   {
// //           //     id: '-U_Carsten_P1',
// //           //     name: 'Carsten',
// //           //     pivot: { isBooked: true, amount: 2, value: 'eventitem participant' },
// //           //   },
// //         ],
// //         variants: [
// //           {
// //             id: -3,
// //             name: 'variant 1',
// //             priceOrPercentage: 100.0,
// //             participants: [
// //               { id: '-U_Ben_P1', name: 'Ben', pivot: { isBooked: true, value: 'eventitem participant' } },
// //               {
// //                 id: '-U_Carsten_P1',
// //                 name: 'Carsten',
// //                 pivot: { isBooked: true, amount: 2, value: 'eventitem participant' },
// //               },
// //             ],
// //           },
// //           {
// //             id: -4,
// //             name: 'variant 2',
// //             priceOrPercentage: 50.0,

// //             participants: [
// //               { id: '-U_Ben_P1', name: 'Ben', pivot: { isBooked: true, value: 'eventitem participant' } },
// //               //   {
// //               //     id: '-U_Carsten_P1',
// //               //     name: 'Carsten',
// //               //     pivot: { isBooked: true, amount: 2, value: 'eventitem participant' },
// //               //   },
// //             ],
// //           },
// //         ],
// //       },
// //     ],
// //   },

// //   {
// //     id: '-E2',
// //     title: 'Skireise 2022 b',
// //     date_start: '2022-12-01T00:00:50+01:00',
// //     date_end: '2022-12-14T00:00:50+01:00',
// //     description: 'Viel Spaß und Ski mit alten und neuen Freunden',
// //     formfields: [
// //       { id: '-E2FF1xxx', order: 0, title: 'Name', field_type: 'text' },
// //       { id: '-E2FF2xxx', order: 2, title: 'Alter', field_type: 'number' },
// //     ],
// //     participants: [
// //       {
// //         id: '-U_Ben_P1',
// //         name: 'Ben name',
// //         user_id: '-U_Ben',
// //         formfields: [
// //           { id: '-E2FF1xxx', pivot: { value: 'Ben S.' } },
// //           { id: '-E2FF2xxx', pivot: { value: 36 } },
// //         ],
// //       },
// //       {
// //         id: '-U_Katha_P1',
// //         name: 'Katha name',
// //         user_id: '-U_Katha',
// //         superior_id: '-U_Carsten_P1',

// //         formfields: [
// //           { id: '-E2FF1xxx', pivot: { value: 'Katha L.' } },
// //           { id: '-E2FF2xxx', pivot: { value: 32 } },
// //         ],
// //       },
// //       {
// //         id: '-U_Carsten_P1',
// //         name: 'Carsten name',
// //         user_id: '-U_Carsten',
// //         formfields: [
// //           { id: '-E2FF1xxx', pivot: { value: 'Carsten R.' } },
// //           { id: '-E2FF2xxx', pivot: { value: 36 } },
// //         ],
// //       },
// //     ],
// //     eventitems: [
// //       {
// //         id: '-E2EI1',
// //         name: 'Hausmiete',
// //         category: null, // 'Unterkunft',
// //         startdatetime: null, // '2022-04-27T00:00:00+02:00',
// //         enddatetime: null, // '2022-04-28T00:00:00+02:00',
// //         minParticipants: null, // 4,
// //         maxParticipants: null, // 20,
// //         description: null, // 'Wir mieten gemeinsam ein Haus mit 2-3 Personen Zimmern',
// //         isMultipleBookable: true,
// //         isPartiallyBookable: true,
// //         isObligatory: true,
// //         isSharedCosts: true,
// //         isEstimatedCosts: false,
// //         costsTotal: 2400,
// //         costsTotalCurrency: 'EUR',
// //         participants: [
// //           { id: '-U_Ben_P1', name: 'Ben', pivot: { value: 'eventitem participant' } },
// //           //   { id: '-U_Carsten_P1', name: 'Carsten', pivot: { isBooked: null, value: 'eventitem participant 1111' } },
// //         ],
// //         variants: [
// //           {
// //             id: '-1',
// //             name: 'variant 1',
// //             priceOrPercentage: 100,
// //             participants: [{ id: '-U_Ben_P1', pivot: { value: 'variant participant ' } }],
// //           },
// //           {
// //             id: '-2',
// //             name: 'variant 2',
// //             priceOrPercentage: 50,
// //             participants: [
// //               {
// //                 id: '-U_Carsten_P1',
// //                 pivot: {
// //                   isBooked: true,
// //                   amount: 3,
// //                   value: 'variant participant ',
// //                   startdatetime: '2022-04-27T00:00:00+02:00',
// //                   enddatetime: '2022-04-28T00:00:00+02:00',
// //                 },
// //               },
// //             ],
// //           },
// //         ],
// //       },
// //       {
// //         id: '-E2EI2',
// //         name: 'item 2',
// //         category: null, // 'Unterkunft',
// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',
// //         minParticipants: null, // 4,
// //         maxParticipants: null, // 20,
// //         description: null, // 'Wir mieten gemeinsam ein Haus mit 2-3 Personen Zimmern',
// //         isMultipleBookable: true,
// //         isPartiallyBookable: false,
// //         isObligatory: false,
// //         isSharedCosts: true,
// //         isEstimatedCosts: false,
// //         costsTotal: 2400,
// //         costsTotalCurrency: 'EUR',
// //         participants: [
// //           { id: '-U_Ben_P1', name: 'Ben', pivot: { isBooked: true, value: 'eventitem participant' } },
// //           //   {
// //           //     id: '-U_Carsten_P1',
// //           //     name: 'Carsten',
// //           //     pivot: {
// //           //       isBooked: false,
// //           //       amount: 3,
// //           //       startdatetime: '2022-01-02',
// //           //       enddatetime: '2022-01-03',
// //           //       value: 'eventitem participant',
// //           //     },
// //           //   },
// //         ],
// //         variants: [
// //           {
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [{ id: -1, pivot: { value: 'variant participant ' } }],
// //           },
// //           {
// //             name: 'variant 2',
// //             priceOrPercentage: 70.0,
// //             participants: [{ id: '-U_Carsten_P1', pivot: { value: 'variant participant ' } }],
// //           },
// //           //   { id: -4, name: 'variant 2', participants: [{ id: -2, pivot: { value: 'variant participant ' } }] },
// //         ],
// //       },
// //     ],
// //   },
// // ]);

// // useRepo(Event).save([
// //   {
// //     id: '-testevent',
// //     title: 'Test event',
// //     date_start: '2022-12-01 12:00:00',
// //     date_end: '2022-12-14 12:00:00',
// //     description: 'Viel Spaß und Ski mit alten und neuen Freunden',
// //     formfields: [
// //       { id: '-E1FF1xxx', order: 0, title: 'Name', field_type: 'text' },
// //       { id: '-E1FF2xxx', order: 2, title: 'Alter', field_type: 'number' },
// //     ],
// //     participants: [
// //       {
// //         id: 'U1_P1',
// //         name: 'Ben name',
// //         user_id: '-U_Ben',
// //         formfields: [
// //           { id: '-E1FF1xxx', pivot: { value: 'Ben S.' } },
// //           { id: '-E1FF2xxx', pivot: { value: 36 } },
// //         ],
// //       },
// //       {
// //         id: '-U_Katha_P1',
// //         name: 'Katha name',
// //         user_id: '-U_Katha',
// //         superior_id: 'U1_P1',

// //         formfields: [
// //           { id: '-E1FF1xxx', pivot: { value: 'Katha L.' } },
// //           { id: '-E1FF2xxx', pivot: { value: 32 } },
// //         ],
// //       },
// //       {
// //         id: 'U2_P2',
// //         name: 'Carsten name',
// //         user_id: '-U_Carsten',
// //         formfields: [
// //           { id: '-E1FF1xxx', pivot: { value: 'Carsten R.' } },
// //           { id: '-E1FF2xxx', pivot: { value: 36 } },
// //         ],
// //       },
// //     ],
// //     eventitems: [
// //       {
// //         id: 'E12',
// //         name: 'E12, NOT shared costs, no variants, no multiple, unit pauschal',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: null,
// //         isAmountIncreasable: false,
// //         costsTotal: 100,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },
// //       {
// //         id: 'E13',
// //         name: 'E13, NOT shared costs, no variants, no multiple, unit days',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'day',
// //         isAmountIncreasable: false,
// //         costsTotal: 100,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 2 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 3 },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },
// //       {
// //         id: 'E14',
// //         name: 'E14, NOT shared costs, no variants, multiple, unit pauschal',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: null,
// //         isAmountIncreasable: false,
// //         costsTotal: 100,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 1 },
// //           },
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 2 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 3 },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },
// //       {
// //         id: 'E15',
// //         name: 'E15, NOT shared costs, no variants, multiple, unit days',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'day',
// //         isAmountIncreasable: false,
// //         costsTotal: 100,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 2 },
// //           },
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 3 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 1 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 1 },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },
// //       {
// //         id: 'E16',
// //         name: 'E16, not shared costs, variants, unit pauschal',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'piece',
// //         isAmountIncreasable: true,
// //         costsTotal: 0,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U4_P4',
// //             name: 'Tim',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E5_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [
// //               { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } },
// //               { id: 'U3_P3', name: 'Sven', pivot: { isBooked: true, amount: 1 } },
// //             ],
// //           },
// //           {
// //             id: 'E5_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 100.0,

// //             participants: [
// //               { id: 'U2_P2', name: 'Carsten', pivot: { isBooked: true, amount: 2 } },
// //               { id: 'U3_P3', name: 'Sven', pivot: { isBooked: true, amount: 2 } },
// //             ],
// //           },

// //           {
// //             id: 'E5_V3',
// //             name: 'variant 3',
// //             priceOrPercentage: 111.0,

// //             participants: [],
// //           },
// //         ],
// //       },
// //       {
// //         id: 'E17',
// //         name: 'E17, not shared costs, variants, unit days',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'days',
// //         isAmountIncreasable: true,
// //         costsTotal: 0,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U4_P4',
// //             name: 'Tim',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E5_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [
// //               { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 2 } },
// //               { id: 'U2_P2', name: 'Sven', pivot: { isBooked: true, amount: 1 } },
// //             ],
// //           },
// //           {
// //             id: 'E5_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 100.0,

// //             participants: [{ id: 'U2_P2', name: 'Carsten', pivot: { isBooked: true, amount: 2 } }],
// //           },

// //           {
// //             id: 'E5_V3',
// //             name: 'variant 3',
// //             priceOrPercentage: 111.0,

// //             participants: [],
// //           },
// //         ],
// //       },
// //     ],
// //   },
// // ]);

// // useRepo(Event).save([
// //   {
// //     id: '-testset',
// //     title: 'Testset',
// //     date_start: '2022-12-01 12:00:00',
// //     date_end: '2022-12-14 12:00:00',
// //     description: 'Viel Spaß und Ski mit alten und neuen Freunden',
// //     formfields: [
// //       { id: '-testset_FF1', order: 0, title: 'Name', field_type: 'text' },
// //       { id: '-testset_FF2', order: 2, title: 'Alter', field_type: 'number' },
// //       { id: '-testset_FF3', order: 3, title: 'Geb', field_type: 'birthdate' },
// //       { id: '-testset_FF4', order: 4, title: 'Startdatum', field_type: 'date' },
// //       { id: '-testset_FF5', order: 5, title: 'E-Mail', field_type: 'email' },
// //     ],
// //     participants: [
// //       {
// //         id: 'U1_P1_testset',
// //         name: 'Ben name',
// //         user_id: '-U_Ben',
// //         formfields: [
// //           { id: '-testset_FF1', pivot: { value: 'Ben S.' } },
// //           //   { id: '-testset_FF2', pivot: { value: 36 } },
// //         ],
// //       },
// //       {
// //         id: '-U_Katha_P_testset',
// //         name: 'Katha name',
// //         user_id: '-U_Katha',
// //         superior_id: 'U1_P1',

// //         formfields: [
// //           { id: '-testset_FF1', pivot: { value: 'Katha L.' } },
// //           { id: '-testset_FF2', pivot: { value: 32 } },
// //         ],
// //       },
// //       {
// //         id: 'U2_P2_testset',
// //         name: 'Carsten name',
// //         user_id: '-U_Carsten',
// //         formfields: [
// //           { id: '-testset_FF1', pivot: { value: 'Carsten R.' } },
// //           { id: '-testset_FF2', pivot: { value: 36 } },
// //         ],
// //       },
// //     ],
// //     eventitems: [
// //       {
// //         id: 'E1',
// //         name: 'shared costs, no variants, no multiple, unit pauschal',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: null,
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true } },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },
// //       {
// //         id: 'E2',
// //         name: 'shared costs, no variants, no multiple, unit day',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: 'day',
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: {
// //               isBooked: true,
// //               amount: 3,
// //               startdatetime: '2022-04-20T00:00:00+02:00',
// //               enddatetime: '2022-04-23T00:00:00+02:00',
// //             },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: {
// //               isBooked: true,
// //               amount: 4,
// //               startdatetime: '2022-04-20T00:00:00+02:00',
// //               enddatetime: '2022-04-24T00:00:00+02:00',
// //             },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: {
// //               isBooked: true,
// //               amount: 5,
// //               startdatetime: '2022-04-20T00:00:00+02:00',
// //               enddatetime: '2022-04-25T00:00:00+02:00',
// //             },
// //           },
// //           {
// //             id: 'U4_P4',
// //             name: 'Katha',
// //             pivot: {
// //               isBooked: false,
// //             },
// //           },
// //         ],
// //         variants: [],
// //       },

// //       {
// //         id: 'E3',
// //         name: 'shared costs, no variants, no multiple, unit pieces',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: 'piece',
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: {
// //               isBooked: true,
// //               amount: 2,
// //               startdatetime: '2022-04-20T00:00:00+02:00',
// //               enddatetime: '2022-04-23T00:00:00+02:00',
// //             },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: {
// //               isBooked: true,
// //               amount: 10,
// //               startdatetime: '2022-04-20T00:00:00+02:00',
// //               enddatetime: '2022-04-24T00:00:00+02:00',
// //             },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: {
// //               isBooked: true,
// //               amount: 1,
// //               startdatetime: '2022-04-20T00:00:00+02:00',
// //               enddatetime: '2022-04-25T00:00:00+02:00',
// //             },
// //           },
// //           {
// //             id: 'U4_P4',
// //             name: 'Katha',
// //             pivot: {
// //               isBooked: false,
// //             },
// //           },
// //         ],
// //         variants: [],
// //       },

// //       {
// //         id: 'E4',
// //         name: 'shared costs, no variants, multiple, unit pauschal',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: null,
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true } },
// //           { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true } },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },

// //       {
// //         id: 'E5',
// //         name: 'shared costs, no variants, multiple, unit day',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'day',
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 2 } },
// //           { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 3 } },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 1 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 2 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 3 },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },

// //       {
// //         id: 'E6',
// //         name: 'shared costs, no variants, multiple, unit piece',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'piece',
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } },
// //           { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 3 } },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 2 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 4 },
// //           },

// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },

// //       {
// //         id: 'E7',
// //         name: 'shared costs, variants, no multiple, unit pauschal',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: null,
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E7_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [{ id: 'U1_P1', name: 'Ben', pivot: { isBooked: true } }],
// //           },
// //           {
// //             id: 'E7_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 100.0,

// //             participants: [
// //               {
// //                 id: 'U2_P2',
// //                 name: 'Carsten',
// //                 pivot: { isBooked: true },
// //               },
// //             ],
// //           },
// //         ],
// //       },

// //       {
// //         id: 'E8',
// //         name: 'shared costs, variants, no multiple, unit day',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: 'day',
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E8_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [{ id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 3 } }],
// //           },
// //           {
// //             id: 'E8_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 100.0,

// //             participants: [
// //               {
// //                 id: 'U2_P2',
// //                 name: 'Carsten',
// //                 pivot: { isBooked: true, amount: 2 },
// //               },
// //             ],
// //           },
// //         ],
// //       },
// //       {
// //         id: 'E9',
// //         name: 'shared costs, no variants, multiple, unit piece',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: 'piece',
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E9_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [{ id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 2 } }],
// //           },
// //           {
// //             id: 'E9_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 70.0,

// //             participants: [
// //               {
// //                 id: 'U2_P2',
// //                 name: 'Carsten',
// //                 pivot: { isBooked: true, amount: 1 },
// //               },
// //             ],
// //           },
// //         ],
// //       },

// //       {
// //         id: 'E10',
// //         name: 'shared costs, variants, no multiple, unit pauschal',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: null,
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E10_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [{ id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } }],
// //           },
// //           {
// //             id: 'E10_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 70.0,

// //             participants: [
// //               { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 2 } },
// //               {
// //                 id: 'U2_P2',
// //                 name: 'Carsten',
// //                 pivot: { isBooked: true, amount: 1 },
// //               },
// //             ],
// //           },
// //         ],
// //       },

// //       {
// //         id: 'E11',
// //         name: 'shared costs, no variants, multiple, unit days',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'days',
// //         isAmountIncreasable: true,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E11_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [{ id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } }],
// //           },
// //           {
// //             id: 'E11_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 100.0,

// //             participants: [
// //               { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 2 } },
// //               { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 2 } },
// //               {
// //                 id: 'U2_P2',
// //                 name: 'Carsten',
// //                 pivot: { isBooked: true, amount: 3 },
// //               },
// //             ],
// //           },
// //         ],
// //       },

// //       {
// //         id: 'E11a',
// //         name: 'shared costs, variants, multiple, unit piece',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'piece',
// //         isAmountIncreasable: true,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E11a_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [{ id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } }],
// //           },
// //           {
// //             id: 'E11a_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 90.0,

// //             participants: [
// //               { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } },
// //               { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } },
// //               {
// //                 id: 'U2_P2',
// //                 name: 'Carsten',
// //                 pivot: { isBooked: true, amount: 3 },
// //               },
// //             ],
// //           },
// //         ],
// //       },

// //       {
// //         id: 'E12',
// //         name: 'shared costs, 3 variants, multiple, unit piece',

// //         isSharedCosts: true,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: 'piece',
// //         isAmountIncreasable: false,
// //         costsTotal: 1000,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E12_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [{ id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } }],
// //           },
// //           {
// //             id: 'E12_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 90.0,

// //             participants: [{ id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } }],
// //           },

// //           {
// //             id: 'E12_V3',
// //             name: 'variant 3',
// //             priceOrPercentage: 100.0,

// //             participants: [
// //               {
// //                 id: 'U2_P2',
// //                 name: 'Carsten',
// //                 pivot: { isBooked: true, amount: 3 },
// //               },
// //             ],
// //           },
// //         ],
// //       },

// //       {
// //         id: 'E13',
// //         name: 'NOT shared costs, no variants, no multiple, unit pauschal',

// //         isSharedCosts: false,
// //         isObligatory: true,
// //         isMultipleBookable: false,
// //         priceUnit: null,
// //         isAmountIncreasable: false,
// //         costsTotal: 100,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },

// //       {
// //         id: 'E14',
// //         name: 'NOT shared costs, no variants, no multiple, unit days',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: false,
// //         priceUnit: 'day',
// //         isAmountIncreasable: false,
// //         costsTotal: 100,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 2 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 3 },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },

// //       {
// //         id: 'E15',
// //         name: 'NOT shared costs, no variants, multiple, unit pauschal',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: null,
// //         isAmountIncreasable: false,
// //         costsTotal: 100,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 1 },
// //           },
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 2 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 3 },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },

// //       {
// //         id: 'E16',
// //         name: 'NOT shared costs, no variants, multiple, unit days',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'day',
// //         isAmountIncreasable: false,
// //         costsTotal: 100,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 2 },
// //           },
// //           {
// //             id: 'U1_P1',
// //             name: 'Ben',
// //             pivot: { isBooked: true, amount: 3 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 1 },
// //           },
// //           {
// //             id: 'U2_P2',
// //             name: 'Carsten',
// //             pivot: { isBooked: true, amount: 1 },
// //           },
// //           {
// //             id: 'U3_P3',
// //             name: 'Sven',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },

// //       {
// //         id: 'E17',
// //         name: 'NOT shared costs, variants, unit pauschal',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'piece',
// //         isAmountIncreasable: true,
// //         costsTotal: 0,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U4_P4',
// //             name: 'Tim',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E17_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [
// //               { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 1 } },
// //               { id: 'U3_P3', name: 'Sven', pivot: { isBooked: true, amount: 1 } },
// //             ],
// //           },
// //           {
// //             id: 'E17_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 100.0,

// //             participants: [
// //               { id: 'U2_P2', name: 'Carsten', pivot: { isBooked: true, amount: 2 } },
// //               { id: 'U3_P3', name: 'Sven', pivot: { isBooked: true, amount: 2 } },
// //             ],
// //           },

// //           {
// //             id: 'E17_V3',
// //             name: 'variant 3',
// //             priceOrPercentage: 111.0,

// //             participants: [],
// //           },
// //         ],
// //       },

// //       {
// //         id: 'E18',
// //         name: 'not shared costs, variants, unit days',

// //         isSharedCosts: false,
// //         isObligatory: false,

// //         isMultipleBookable: true,
// //         priceUnit: 'days',
// //         isAmountIncreasable: true,
// //         costsTotal: 0,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U4_P4',
// //             name: 'Tim',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [
// //           {
// //             id: 'E18_V1',
// //             name: 'variant 1',
// //             priceOrPercentage: 50.0,
// //             participants: [
// //               { id: 'U1_P1', name: 'Ben', pivot: { isBooked: true, amount: 2 } },
// //               { id: 'U2_P2', name: 'Sven', pivot: { isBooked: true, amount: 1 } },
// //             ],
// //           },
// //           {
// //             id: 'E18_V2',
// //             name: 'variant 2',
// //             priceOrPercentage: 100.0,

// //             participants: [{ id: 'U2_P2', name: 'Carsten', pivot: { isBooked: true, amount: 2 } }],
// //           },

// //           {
// //             id: 'E18_V3',
// //             name: 'variant 3',
// //             priceOrPercentage: 111.0,

// //             participants: [],
// //           },
// //         ],
// //       },
// //       {
// //         id: 'E19',
// //         name: 'not shared costs, NO variants, obligatory',

// //         isSharedCosts: false,
// //         isObligatory: true,

// //         isMultipleBookable: true,
// //         priceUnit: null,
// //         isAmountIncreasable: true,
// //         costsTotal: 101,

// //         startdatetime: '2022-04-27T00:00:00+02:00',
// //         enddatetime: '2022-04-28T00:00:00+02:00',

// //         costsTotalCurrency: 'EUR',

// //         participants: [
// //           {
// //             id: 'U4_P4',
// //             name: 'Tim',
// //             pivot: { isBooked: false },
// //           },
// //         ],
// //         variants: [],
// //       },
// //     ],
// //   },
// // ]);
