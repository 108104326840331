
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  isPlatform,
} from '@ionic/vue';
import { defineComponent, ref, provide } from 'vue';
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
} from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    // create an empty ref
    const routerOutletRef = ref(null);
    // provide the value in the component hierarchy
    provide('routerOutlet', routerOutletRef);

    return {
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      routerOutletRef,
    };
  },

  methods: {
    preventSwipe(event: any) {
      if (isPlatform('ios') && event.touches[0].pageX < 34.0) {
        // console.log('prevented');
        event.preventDefault();
      }
    },

    touchmoveit(event: any) {
      console.log('moved', event);
      event.preventDefault();
    },
  },
});
