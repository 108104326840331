import axios from 'axios';
import { API_URL } from '@/env';

const apiRequest = async (options: Object) => {
  //   console.log('requesting!', options);

  try {
    const response = await axios(options);
    // console.log('response: ', response);
    return response;
  } catch (error: any) {
    // console.error(error);

    // if CSRF invalid: get new CSRF
    if (error?.response?.data?.message === 'CSRF token mismatch.') {
      console.log('CSRF invalid, getting new one');

      await axios.get(`${API_URL}/api/auth/csrf`, { withCredentials: true });

      // run original request again
      const response = await axios(options);
      return response;

      //   try {
      //     // console.log('response after new CSRF: ', response);
      //     return response;
      //   } catch (error2: any) {
      //     // console.error('error after new CSRF', error2);
      //     throw error2;
      //   }
    }

    // if unauthorized: force redirect to login
    if (error.response.status === 401) {
      console.log('redirect');
      // eslint-disable-next-line no-restricted-globals
      location.href = '/login';
    }

    // otherwise: rethrow error
    throw error;
  }
};

export default apiRequest;
